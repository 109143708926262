body, html, #root, .App {
  position: fixed !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  background: #F5F7F6;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  touch-action: none;
}

* {
  font-family: "IBM Plex Sans", "Arial", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

.font_preload {
  position: fixed;
  z-index: -1;
}

#renderer {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  touch-action: none;
}

.__floater, .react-joyride__spotlight {
  transition: opacity 0.6s ease 0s, transform 0.3s ease 0s !important;
}

.react-joyride__overlay {
  transition: opacity 0.25s;
}

.react-joyride__tooltip button[data-action="primary"] {
  font-size: 14px;
  font-weight: 500;
  padding-right: 8px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  outline: none;
}

.react-joyride__tooltip div {
  font-size: 14px;
  line-height: 22px;
}

.react-joyride__tooltip div p {
  font-size: 14px;
  line-height: 22px;
  color: #637381;
}

.react-joyride__tooltip h4 {
  font-size: 16px !important;
}


.noselect {  
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.textselect {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
   -khtml-user-select: text; /* Konqueror HTML */
     -moz-user-select: text; /* Old versions of Firefox */
      -ms-user-select: text; /* Internet Explorer/Edge */
          user-select: text; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

input { -webkit-user-select: text; }

input, input:before, input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
} 

.reference-disabled {
  opacity: 0.25;
}

/* Loading Animation */
.dots-container {
  animation: fadein 2s;
}

.dots {
  color: #5F5F5F;
  opacity: 0.5;
  transform-origin: 50% 50%;
}

.dot1 {
  position: absolute;
  top: 0;
  left: calc(12.5% - 12px);
}

.dot2 {
  position: absolute;
  top: 0;
  left: calc(37.5% - 12px);
}

.dot3 {
  position: absolute;
  top: 0;
  left: calc(62.5% - 12px);
}

.heart {
  position: absolute;
  top: 0;
  left: calc(87.5% - 12px);
}

.dot1a {
  animation: dot1-animation 2s linear infinite;
  color: #FFCF54;
  opacity: 0;
}

.dot2a {
  animation: dot2-animation 2s linear infinite;
  color: #FFCF54;
  opacity: 0;
}
.dot3a {
  animation: dot3-animation 2s linear infinite;
  color: #FFCF54;
  opacity: 0;
}
.hearta {
  animation: heart-animation 2s linear infinite;
  color: #FF3C38;
  opacity: 0;
}

.viewer-backdrop {
  backdrop-filter: blur(4px);
  background-color: rgba(0,0,0,0.6);
}

@keyframes dot1-animation {
  0%, 25%, 50%, 75%, 100% { animation-timing-function: ease-in-out; }
  0% { opacity: 0; }
  25% { opacity: 1; transform: scale(1.05); }
  50% { opacity: 0; }
  75% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes dot2-animation {
  0%, 25%, 50%, 75%, 100% { animation-timing-function: ease-in-out; }
  0% { opacity: 0; }
  25% { opacity: 0; }
  50% { opacity: 1; transform: scale(1.05); }
  75% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes dot3-animation {
  0%, 25%, 50%, 75%, 100% { animation-timing-function: ease-in-out; }
  0% { opacity: 0; }
  25% { opacity: 0; }
  50% { opacity: 0; }
  75% { opacity: 1; transform: scale(1.05); }
  100% { opacity: 0; }
}

@keyframes heart-animation {
  0%, 25%, 50%, 75%, 100% { animation-timing-function: ease-in-out; }
  0% { opacity: 1; transform: scale(1.05); }
  25% { opacity: 0; }
  50% { opacity: 0; }
  75% { opacity: 0; }
  100% { opacity: 1; transform: scale(1.05); }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


@keyframes writing-animation {
  0%, 20%, 40%, 60%, 100% { animation-timing-function: ease-in-out; }
  0% { opacity: 0.25;  }
  20% { opacity: 0.75; transform: scale(1.05) translateY(-6px); }
  40% { opacity: 0.5; transform: translateY(0px); }
  60% { opacity: 0.25; }
  100% { opacity: 0.25; }
}


@keyframes rainbow-animation {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}